<template>
  <div style="display:flex;justify-content:center;" :class="{ fullscreen: landing.sections.styling.Fullscreen, live: live  }">
    <div :class="`device-container ${pageMode}`"
      :style="{ backgroundImage: `url(${landing.sections.styling.BackgroundImage})` }">
      <div class="device-container-inner" :style="deviceContainerInner">
        <div v-if="landing.sections.styling.BackgroundVideo" class="videoContainer">
          <iframe v-if="videoType === 'youtube'" :src="videoSource" height="100%" width="100%" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
          <iframe v-else-if="videoType === 'vimeo'" :src="videoSource" height="100%" width="100%" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
          <video v-else :src="videoSource" autoplay muted loop></video>
        </div>
        <div class="landing-widget" :style="landingWidgetStyle">
          <div ref="graphicContainer" class="landing-container" :style="landingContainerStyle">
            <div class="contentContainer" :style="contentContainerStyle">
              <div class="section-container" :style="containerSectionStyle">
                <div v-if="landing.sections.logo.Show" class="logo-container" :style="logoContainerStyle">
                  <a :href="landing.sections.logo.Link">
                    <img class="section-select" :src="landing.sections.logo.Image" alt="Logo" :style="logImgStyle" @click="selectBlock('logo')" />
                  </a>
                </div>
                <div>
                </div>
                <div :style="mainContainerStyle">
                  <div class="widget-block widget-content">
                    <div class="section-select" style="text-align:center;" @click="selectBlock('result')">
                      <div :style="questionStyle">
                        {{ landing.sections.main.Question }}
                      </div>
                    </div>
                    <div v-if="landing.votes.share.Show" :style="shareStyle" class="section-select" @click="selectBlock('share')" >
                      <div class="share-section">
                        <div v-if="landing.votes.share.ShowTitle" class="share-section-title" :style="shareTitleStyle">{{ landing.votes.share.Title }}</div>
                        <div class="share-item-wrapper">
                          <a v-if="landing.votes.share.FacebookEnabled" target="_blank" :href="getShareUrl('facebook')" class="share-item">
                            <img :src="require(`@assets/images/socials1/facebook.webp`)" width="32" height="32"/>
                          </a>
                          <a v-if="landing.votes.share.TwitterEnabled" target="_blank" :href="getShareUrl('x')" class="share-item">
                            <img :src="require(`@assets/images/socials1/x.webp`)" width="32" height="32"/>
                          </a>
                          <a v-if="landing.votes.share.LinkedinEnabled" target="_blank" :href="getShareUrl('linkedin')" class="share-item">
                            <img :src="require(`@assets/images/socials1/linkedin.webp`)" width="32" height="32"/>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="section-select" style="text-align:center;" @click="selectBlock('result')">
                      <div class="options-section mt-3">
                        <div v-for="(item, key) in voteOptions" :key="key" class="form-group mb-4">
                          <div class="vote-option-item"
                            :class="{ 'allow-multiple': landing.votes.options.AllowMultiple }" :style="optionStyle">
                            <span class="d-block">{{ item.text }}</span>
                            <div class="progress my-2">
                              <div class="progress-bar progress-bar-striped" :class="item.color" role="progressbar" :style="`width: ${ Math.ceil(100 * item.voteCount / totalVoteCount)}%`"
                                :aria-valuenow="Math.ceil(100 * item.voteCount / totalVoteCount)" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <span class="d-block percent-span" :style="answerVoteStyle">{{ item.voteCount }} Votes - {{ Math.ceil(100 * item.voteCount / totalVoteCount) }}%</span>
                          </div>
                        </div>
                        <span class="d-block" :style="totalVoteStyle">Total Votes: <b>{{ totalVoteCount }}</b></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="landing.poweredBy && landing.poweredBy.enabled" class="text-center mt-2">
              <span class="powered-by-content" :style="{ color: landing.sections.legalFooter.Color }">powered by <a
                  :href="landing.poweredBy.url" target="_blank">{{ landing.poweredBy.name }} </a></span>
            </div>
            <div class="legal-container section-select" :style="legalContainerStyle"
              @click="selectBlock('legalFooter')">
              <div>
                {{ landing.sections.legalFooter.Content }}
              </div>
              <div>
                <template v-if="landing.sections.legalFooter.PolicyLabel || landing.sections.legalFooter.ServiceLabel">
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.PolicyLabel }}
                  </a>
                  -
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.ServiceLabel }}
                  </a>
                </template>
                <template v-else>
                  <a href="javascript:;" :style="{ color: landing.sections.legalFooter.LinkColor }">
                    {{ landing.sections.legalFooter.LinkLabel }}
                  </a>
                </template>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    landing: {
      type: [Object],
      default: null,
    },
    pageMode: {
      type: String,
      default: '',
    },
    selectedTab: {
      type: String,
      default: '',
    },
    live: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },

  computed: {
    voteOptions() {
      let res = this.landing.voteOptions.filter(item => item.text);
      if (!this.live) {
        res = res.map(item => {
          return {
            ...item,
            voteCount: Math.ceil(Math.random() * 5) + 1,
          }
        })
      }
      res = res.sort((a,b) => a.voteCount < b.voteCount ? 1 : -1 )
      return res
    },

    totalVoteCount() {
      return this.voteOptions.reduce((a, c) => {
        return a + c.voteCount
      }, 0)
    },

    totalVoteStyle() {
      return {
        color: this.landing.votes.result.HeadingColor,
        fontSize: `20px`,
        textAlign: `${this.landing.votes.result.Align}`,
      }
    },


    answerVoteStyle() {
      return {
        textAlign: `${this.landing.votes.result.AnswerVoteAlign}`,
        fontSize: `${this.landing.votes.result.AnswerFontSize}px`,
      }
    },

    logImgStyle() {
      return {
        maxWidth: this.landing.sections.logo.MaxWidth + 'px',
        width: '100%',
        margin: '0 auto',
      }
    },

    shareStyle() {
      return {
        textAlign: `${this.landing.votes.share.Align}`,
      }
    },

    shareTitleStyle() {
      return {
        color: this.landing.votes.share.TitleColor,
        fontSize: `${this.landing.votes.share.TitleFontSize}px`,
      }
    },

    questionStyle() {
      return {
        color: this.landing.votes.result.HeadingColor,
        fontSize: `${this.landing.votes.result.FontSize}px`,
        textAlign: `${this.landing.votes.result.Align}`,
      }
    },

    questionDescriptionStyle() {
      return {
        display: 'block',
        textAlign: `${this.landing.votes.result.Align}`,
        color: this.landing.sections.main.DescriptionColor,
        padding: `${this.landing.sections.main.DescriptionPadding}px`,
        fontSize: `${this.landing.sections.main.DescriptionFontSize}px`,
      }
    },

    deviceContainerInner() {
      return {
        backgroundColor: this.landing.sections.styling.BackgroundColor,
      }
    },

    containerSectionStyle() {
      return {
        borderRadius: `${this.landing.sections.styling.Roudness}px`,
      }
    },

    logoContainerStyle() {
      return {
        textAlign: this.landing.sections.logo.Align,
        backgroundColor: this.landing.sections.logo.BackgroundColor,
        padding: `${this.landing.sections.logo.Padding}px`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      }
    },

    legalContainerStyle() {
      return {
        margin: `10px 20px`,
        textAlign: this.landing.sections.legalFooter.Align,
        color: this.landing.sections.legalFooter.Color,
        fontSize: `${this.landing.sections.legalFooter.fontSize}px`,
      }
    },

    contentContainerStyle() {
      let style = {
        paddingBottom: '10px',
        overflow: 'hidden',
      }

      if (!this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.votes.result.BackgroundColor
        style.borderRadius = `${this.landing.sections.styling.Roudness}px`
      }

      return style
    },

    landingWidgetStyle() {
      let style = {}
      if (this.live) {
        style.minHeight = '100vh'
      }
      if (!this.landing.sections.styling.Fullscreen) {
        style.padding = '30px 10px'
      }

      return style
    },

    landingContainerStyle() {
      let style = {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: this.landing.sections.styling.BorderColor || 'transparent',
        borderImage: 'initial',
      }

      if (this.landing.sections.styling.Fullscreen) {
        style.backgroundColor = this.landing.votes.result.BackgroundColor
        if (!this.landing.sections.logo.Show)
          style.justifyContent = 'center'
      } else {
        style.width = `${this.landing.sections.styling.MaxWidth}px`
      }

      return style
    },

    inputGroupStyle() {
      return {
        width: '100%',
        marginBottom: '15px',
      }
    },

    optionStyle() {
      return {
        width: '100%',
        padding: '6px 18px',
        fontSize: `${this.landing.votes.options.FontSize}px`,
        borderRadius: `${this.landing.votes.options.Roudness}px`,
        border: `1px solid ${this.landing.votes.options.BorderColor}`,
        backgroundColor: this.landing.votes.options.BackgroundColor,
        color: this.landing.votes.options.Color,
      }
    },

    mainContainerStyle() {
      if (!this.landing.sections.styling.Fullscreen) {
        return {
          padding: `${this.landing.votes.result.Padding}px`,
          paddingBottom: `${this.landing.votes.result.Padding}px`,
        }
      } else {
        return {
          width: `${this.landing.sections.styling.MaxWidth}px`,
          padding: `${this.landing.votes.result.Padding}px`,
          paddingBottom: '0px',
          margin: '0 auto'
        }
      }
    },

    actionButtonStyle() {
      return {
        backgroundColor: this.landing.votes.actionButton.BackgroundColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor:
          this.landing.votes.actionButton.BorderColor || 'transparent',
        color: this.landing.votes.actionButton.Color,
        display: 'block',
        borderRadius: `${this.landing.votes.actionButton.Roudness}px`,
        fontSize: `${this.landing.votes.actionButton.FontSize}px`,
        lineHeight: `38px`,
        padding: `${this.landing.votes.actionButton.Padding}px`,
        width: '100%',
      }
    },

    actionDisclaimerStyle() {
      return {
        color: this.landing.votes.actionButton.DisclaimerColor,
        fontSize: `${this.landing.votes.actionButton.DisclaimerFontSize}px`,
        display: 'block',
        width: '100%',
      }
    },

    videoType() {
      let link = this.landing.sections.styling.BackgroundVideo || ""
      if (link.match(/\/\/www\.youtube\.com/)) {
        return 'youtube'
      } else if (link.match(/\/\/vimeo.com/)) {
        return 'vimeo'
      }

      return 'video'
    },

    videoSource() {
      let link = this.landing.sections.styling.BackgroundVideo || ""

      if (this.videoType === 'youtube') {
        let videoId = link.replace(/.*\?v=/, '').replace(/&.*/, '')
        return `https://www.youtube.com/embed/${videoId}?controls=0&amp;autoplay=1&amp;mute=1&amp;loop=1`
      } else if (this.videoType === 'vimeo') {
        let videoId = link.replace(/.*com\//, '')
        return `https://player.vimeo.com/video/${videoId}?title=0&amp;byline=0&amp;portrait=0&amp;autoplay=1&amp;loop=1&amp;muted=1&amp;background=1`
      }

      return link.replace(/(https|http)/, 'https')
    },

    config() {
      return this.$store.getters['app/config']
    },

    domainUrl() {
      let url = '';
      if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:8080'
      }
      url = window.location.origin
      return url;
    }
  },

  watch: {
    landing: {
      handler(val, oldVal) {
        this.addCustomStyle()
      },
      deep: true,
    },
  },

  mounted() {
    this.addCustomStyle()
  },

  methods: {
    getShareUrl(type) {
      let url = ''
      let link = `${this.domainUrl}/pages/${this.landing.link}/${this.landing.business_id}/result`
      switch (type) {
        case 'x':
          url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(link)}`
          break;
        case 'linkedin':
          url = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(link)}`
          break;
        case 'pinterest':
          url = `http://pinterest.com/pin/create/link/?url=${encodeURIComponent(link)}`
          break;
        case 'facebook':
          url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}`
          break;
        default:
          break;
      }
      return url
    },
    selectBlock(block) {
      if (this.live) return
      this.$emit('select-block', block)
    },

    addCustomStyle() {
      document.getElementById('landing-css') && document.getElementById('landing-css').remove()

      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'landing-css'
      style.innerHTML = `
          .landing-container .vti__dropdown {
            background-color: ${this.landing.votes.options.BackgroundColor};
            border-radius: ${this.landing.votes.options.Roudness}px;
          }
          .landing-container .vti__input {
            padding: 12px 20px 12px 5px;
            height: initial;
            font-size: ${this.landing.votes.options.FontSize}px;
            color: ${this.landing.votes.options.Color};
            border-radius: ${this.landing.votes.options.Roudness}px;
            background-color: ${this.landing.votes.options.BackgroundColor};
          }
          .landing-container .vue-tel-input {
            border-color: ${this.landing.votes.options.BorderColor};
            border-radius: ${this.landing.votes.options.Roudness}px;
            background-color: ${this.landing.votes.options.BackgroundColor};
          }`
      document.querySelector('head').append(style)
    }
  },
}
</script>

<style lang="scss">
.options-section {
  text-align: left;

  .vote-option-item {
    .percent-span {
      font-size: 16px;
    }
  }
}

.share-section {
  padding: 5px 0;
  .share-item-wrapper {
    a.share-item {
      display: inline-block;
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}

.disclaimer-section {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-top: 20px;
  text-wrap: balance;

  .custom-checkbox {
    padding-left: 30px;
    margin-bottom: 5px;
  }

  .custom-control-label:before,
  .custom-control-label:after {
    width: 20px;
    height: 20px;
    left: -30px;
    top: 0px;
  }

  .custom-control-input {
    width: 20px;
    height: 20px;
    top: -4px;
  }
}
</style>

<style lang="scss" scoped>
.live {
  .device-container  {
    border-radius: 0px;
    border: none;
    &:before, &:after {
      display: none;
    }
  }
}
.social-box {

  a {
    font-size: 50px;
  }

  .uil-facebook {
    color: #4267B2;
  }

  .uil-twitter {
    margin-left: 20px;
    color: #1DA1F2;
  }
}

.fullscreen {
  .landing-container {
    flex: 1 1 auto;
    border-radius: 0px !important;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.device-container {
  min-width: 100%;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center;
  border: 2px solid #d9dfe6;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &.design {
    min-width: 90%;
  }

  &:before {
    content: " ";
    border: 2px solid #d9dfe6;
    position: absolute;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    top: -9px;
    left: -10px;
    border-radius: 36px;
    z-index: -1;
  }
}

.device-container-inner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.landing-widget {
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {

  video,
  iframe {
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
  }
}

.section-select {
  cursor: pointer;
  border: 1px solid transparent;
}

.device-container.design .section-select:hover {
  border-color: #4284f4;
}

.fullscreen {
  .landing-widget {
    align-items: inherit;
  }
}

.powered-by-content {
  padding-right: 10px;
  display: block;

  a {
    color: inherit;
    font-weight: bold;

    &:hover {
      text-decoration: underline !important;
    }

    ;
  }
}

@media screen and (max-width: 768px) {
  .landing-wrapper {
    &:not(.fullscreen) {
      .landing-widget {
        padding: 10px !important;
        display: flex;
        align-items: center;
      }
    }
    .landing-container {
      width: 100%!important;
      .main-container {
        width: 100%!important;
      }
    }
  }
}
</style>